.swiper {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.swiper-slide {
  display: flex !important;
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide.swiper-slide-active {
  transform: scale(2) !important;
}

#chakra-toast-manager-bottom {
  z-index: 30000 !important;
}

.partner-swiper .swiper-slide.swiper-slide-active {
  transform: none !important;
}

.partner-swiper .swiper-slide img {
  object-fit: contain !important;
  height: 35px;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

@font-face {
font-family: '__Rubik_de861b';
src: url(/_next/static/media/6bff33d9e8452824-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 100 400;
font-style: normal;
}

@font-face {
font-family: '__Rubik_de861b';
src: url(/_next/static/media/2c1b47d4a3bdcfef-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__Rubik_de861b';
src: url(/_next/static/media/935f2dd0393f8243-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__Rubik_de861b';
src: url(/_next/static/media/e643a12c7b48718a-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 900;
font-style: normal;
}

@font-face {
font-family: '__Rubik_de861b';
src: url(/_next/static/media/1718841066c6a5d2-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600 900;
font-style: italic;
}@font-face {font-family: '__Rubik_Fallback_de861b';src: local("Arial");ascent-override: 88.38%;descent-override: 23.63%;line-gap-override: 0.00%;size-adjust: 105.79%
}.__className_de861b {font-family: '__Rubik_de861b', '__Rubik_Fallback_de861b'
}

@font-face {
font-family: '__luckiestGuy_474d25';
src: url(/_next/static/media/944b8438f24ffc2c-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__luckiestGuy_Fallback_474d25';src: local("Arial");ascent-override: 60.24%;descent-override: 25.44%;line-gap-override: 0.00%;size-adjust: 116.72%
}.__className_474d25 {font-family: '__luckiestGuy_474d25', '__luckiestGuy_Fallback_474d25';font-weight: 400;font-style: normal
}

