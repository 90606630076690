.swiper {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.swiper-slide {
  display: flex !important;
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide.swiper-slide-active {
  transform: scale(2) !important;
}

#chakra-toast-manager-bottom {
  z-index: 30000 !important;
}

.partner-swiper .swiper-slide.swiper-slide-active {
  transform: none !important;
}

.partner-swiper .swiper-slide img {
  object-fit: contain !important;
  height: 35px;
  width: fit-content !important;
}
